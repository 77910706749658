<template>
  <div>
    <!-- input Recherche -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Recherche</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Recherche"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group v-if="user.role === 'admin' || user.role === 'sales'">
        <b-button
          :to="{ name: 'supplier-add' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Ajouter fournisseur</span>
        </b-button>
      </b-form-group>
    </div>
    <div
      v-if="isLoading === true"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="suppliers"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'id'"
          class="text-nowrap"
        >
          <qrcode-vue
            style="display:inline"
            :value="props.row.id.toString()"
            :size="size"
            level="H"
          />
          <p class="ml-2">
            {{ props.row.id }}
          </p>

        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-warning"
              :to="{ name: 'supplier-edit',
                     params: { id: props.row.id} }"
            >    <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteSupplier(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />
            <router-link
              class="text-info"
              :to="{ name: 'supplier-show',
                     params: { id: props.row.id} }"
            >  <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer ml-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Détail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Affichage 1 à
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> de {{ props.total }} résultats trouvés </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButton,
  BSpinner, BTooltip,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    QrcodeVue,
  },
  data() {
    return {
      isLoading: false,
      pageLength: 10,
      dir: false,
      user: {},
      size: 80,
      columns: [
        {
          label: 'Refrence',
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: 'Reference',
          },
        },
        {
          label: 'Fournisseur',
          field: 'username',
          filterOptions: {
            enabled: true,
            placeholder: 'Fournisseur',
          },
        },
        {
          label: 'Email',
          field: 'email',
          filterOptions: {
            enabled: true,
            placeholder: 'Email',
          },
        },
        {
          label: 'Telephone',
          field: 'phone',
          filterOptions: {
            enabled: true,
            placeholder: 'Telephone',
          },
        },
        {
          label: 'Depot',
          field: 'repository',
          filterOptions: {
            enabled: true,
            placeholder: 'Depot',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      suppliers: [],
      searchTerm: '',
    }
  },
  created() {
    this.getSuppliers()
    this.user = storeAuth.state.user
  },
  methods: {
    async getSuppliers() {
      this.isLoading = true
      const { data } = await axios.get('api/auth/users/suppliers/all/')
      this.suppliers = data
      this.isLoading = false
    },

    deleteSupplier(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.isLoading = true
        if (result.value) {
          axios
            .put(`/api/auth/users/delete/${id}/`)
            .then(() => {
              this.isLoading = false
              this.getSuppliers()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'Fournisseur supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.isLoading = false
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.isLoading = false
          this.$swal({
            title: 'Fermer',
            text: 'Voiture Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
